<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('user.label', 2)" />
      <button class="small" @click="openModal({ email: '' }, 'editUser')">
        +
      </button>
    </div>

    <entity-table
      v-model:sort-by="sortBy"
      v-model:sort-direction="sortDirection"
      :fields="fields"
      :entities="entities"
      :current-page="currentPage"
      :total-items="totalItems"
      :loading="loadingEntities"
      @update:page="currentPage = $event; loadEntities()"
      @update:sort="loadEntities()"
      @click:edit="openModal($event, 'editUser')"
      @click:delete="openModal($event, 'deleteUser')"
    >
      <template #cell(roles)="{ value }">
        <div v-html="value.map((v) => $t(`user.role.${v.toUpperCase()}`)).join(',<br>')" />
      </template>
    </entity-table>

    <edit-user-modal @update="loadEntities()" />
    <delete-modal
      type="deleteUser"
      entity-translation-key="user"
      label="email"
      @update="loadEntities()"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditUserModal from '@/components/entities/user/EditUserModal.vue';

export default {
  name: 'UserIndexPage',
  components: {
    EditUserModal,
    DeleteModal,
    EntityTable,
  },
  setup() {
    const store = useStore();
    const { user: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/users', { sort: 'email', direction: 'asc' });

    const openModal = (entity, modal) => {
      store.dispatch('modals/openModal', { entity, modal });
    };

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      openModal,
    };
  },
};
</script>
