<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('registerTheme.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { modal: 'editRegisterTheme' , entity: newTheme })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editRegisterTheme', entity: $event })"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deleteRegisterTheme', entity: $event })"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    />

    <delete-modal type="deleteRegisterTheme" entity-translation-key="registerTheme" label="name" @update="loadEntities" />
    <edit-register-theme-modal @update="loadEntities" />
  </div>
</template>
<script>
import useLoadEntities from '@/hooks/api/loadEntities';
import useEntityFields from '@/hooks/api/fields';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditVatRateModal from '@/components/entities/vat_rate/EditVatRateModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditRegisterThemeModal from '@/components/entities/register_theme/EditRegisterThemeModal.vue';
import { REGISTER_THEME_COLORS } from '@/constants';

export default {
  name: 'RegisterThemeIndex',
  components: {
    EditRegisterThemeModal, DeleteModal, EditVatRateModal, EntityTable,
  },
  setup() {
    const { registerTheme: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/register_themes', { sort: 'name' });

    const newTheme = {
      name: '',
    };
    REGISTER_THEME_COLORS.forEach((color) => {
      newTheme[color] = {
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1.0,
      };
    });

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      newTheme,
    };
  },
};
</script>
