<template>
  <modal-form
    v-slot="{ properties, errors }"
    autocomplete="off"
    type="editUser"
    :options="{
      properties: {
        email: '',
        roles: [],
      },
      label: 'User',
      postUri: '/users',
    }"
    @update="$emit('update')"
  >
    <fancy-input v-model="properties.email" :label="$t('user.email.label')" :error="errors.email" required />
    <multiselect
      v-model="properties.roles"
      :options="userRoles"
      label="label"
      :allow-empty="false"
      mode="tags"
      value-prop="key"
      :form-label="$t('user.role.label')"
    />
  </modal-form>
</template>

<script>
import { useI18n } from 'vue-i18n';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import { USER_ROLES } from '@/constants';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';

export default {
  name: 'EditUserModal',
  components: {
    SimpleDropdown,
    ModalForm,
    FancyInput,
    Multiselect,
  },
  props: {
    entity: { type: Object, default: () => {} },
  },
  emits: ['update'],
  setup() {
    const { t } = useI18n();
    const userRoles = [];
    USER_ROLES.forEach((entry) => {
      userRoles.push({ key: entry, label: t(`user.role.${entry}`) });
    });

    return {
      userRoles,
      userRoleArray: Object.values(USER_ROLES),
    };
  },
};
</script>
